<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "PageLayouts.EditPageLayout",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-show="detailRequestEnd && pageLayoutData.isOk"
        classes="mt-2"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && pageLayoutData.isOk"
    class="form-page-layout-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t("PageLayouts.Name", {}, { locale: this.$store.state.activeLang })
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="pageLayoutData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "PageLayouts.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          disabled
          :value="pageLayoutData.formulaName"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "PageLayouts.ActiveButtons",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row mt-3">
          <div class="col-md-3">
            <div class="form-check">
              <label for="FormTemplates" class="form-label">{{
                $t(
                  "PageLayouts.FormTemplates",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="FormTemplates"
                v-model="pageLayoutData.isFormTemplateButtonActive"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-check">
              <label for="IsDeleteButtonActive" class="form-label">{{
                $t(
                  "PageLayouts.DeleteButtonActive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsDeleteButtonActive"
                v-model="pageLayoutData.isDeleteButtonActive"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-check">
              <label for="IsEditButtonActive" class="form-label">{{
                $t(
                  "PageLayouts.EditButtonActive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsEditButtonActive"
                v-model="pageLayoutData.isEditButtonActive"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-check">
              <label for="IsHistoryButtonActive" class="form-label">{{
                $t(
                  "PageLayouts.HistoryButtonActive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsHistoryButtonActive"
                v-model="pageLayoutData.isHistoryButtonActive"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "PageLayouts.MobileSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row mt-3">
          <div class="col-md-3">
            <div class="form-check">
              <label for="IsMobileLayout" class="form-label">{{
                $t(
                  "PageLayouts.IsMobileLayout",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsMobileLayout"
                v-model="pageLayoutData.isMobileLayout"
              />
            </div>
          </div>
          <div class="col" v-if="pageLayoutData.isMobileLayout">
            <label for="DependentPageLayout" class="form-label">{{
              $t(
                "PageLayouts.DependentPageLayout",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :allowEmpty="true"
              :isParameters="true"
              :selectedData="selectedDependendtPageLayout"
              @onChange="mobilePageLayoutChange"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryPageLayoutsWithFormula?customObjectFormulaName={0}&isMobileLayout=false&publicId={1}',
                  this.pageLayoutData.customObjectFormulaName,
                  this.pageLayoutData.publicId
                )
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="form-check">
        <label for="IsMain" class="form-label">{{
          $t("PageLayouts.Main", {}, { locale: this.$store.state.activeLang })
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsMain"
          v-model="pageLayoutData.isMain"
        />
      </div>
      <div class="form-check">
        <label for="IsApiModelEnabled" class="form-label">{{
          $t(
            "PageLayouts.IsApiModelEnabled",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsApiModelEnabled"
          v-model="pageLayoutData.isApiModelEnabled"
        />
      </div>
      <div class="form-check">
        <label for="isCellEditingEnabled" class="form-label">{{
          $t(
            "PageLayouts.IsCellEditingEnabled",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="isCellEditingEnabled"
          v-model="pageLayoutData.isCellEditingEnabled"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="pageLayoutData.isActive"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      classes="mt-2"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewPageLayout",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      selectedDependendtPageLayout: [],
      disabledButtonClasses: ".btn-save",
      errors: [],
      pageLayoutData: [],
      detailRequestEnd: false,
    };
  },
  methods: {
    mobilePageLayoutChange(selected) {
      this.pageLayoutData.dependentPageLayoutFormulaName = selected.key;
    },
    getPageLayout() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-PageLayoutGet?id={0}&cacheGet=false&coId={1}",
            this.$route.params.pageLayoutId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.detailRequestEnd = true;
          this.pageLayoutData = response.data;
          if (response.data.isOk) {
            if (
              !String.isNullOrWhiteSpace(
                this.pageLayoutData.dependentPageLayoutFormulaName
              ) &&
              !String.isNullOrWhiteSpace(
                this.pageLayoutData.dependentPageLayoutName
              )
            ) {
              this.selectedDependendtPageLayout.push({
                key: this.pageLayoutData.dependentPageLayoutFormulaName,
                value: this.pageLayoutData.dependentPageLayoutName,
              });
            }

            this.pageLayoutData.customObjectFormulaName =
              this.$store.getters._customObjectDto.formulaName;
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-page-layout-edit");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-PageLayoutUpdate", { ...this.pageLayoutData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/PageLayouts/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getPageLayout();
  },
};
</script>
